import * as React from 'react';
import Section from '../Section';

function Introduction() {
  return (
    <Section>
      <div className="p-16 smMax:px-8 smMax:pb-0 smMax:pt-24">
        <div className="">
          <h2 className="font-bold text-4xl">
            Mangools API
          </h2>
          <p className="text-2xl mt-4">
            Mangools API is a REST api that enables access to all Mangools SEO tools.
          </p>
        </div>
        <div className="mt-8" style={{display: 'none'}}>
          <div className="rounded-lg border border-solid border-gray-600">
            <pre>
              <code className="language-js text-sm">
                {`fetch('http://example.com/movies.json')
    .then(response => response.json())
    .then(data => console.log(data)
  );`}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Introduction;
