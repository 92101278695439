import React from 'react';

function Path({ path }) {
  // eslint-disable-next-line no-unused-vars
  const [firstSlash, ...pathArr] = path.split('/');

  return pathArr.map(pathPart => {
    if (pathPart.startsWith('{') && pathPart.endsWith('}')) {
      return (
        <span key={pathPart} className="endpoint-path-url">
          &#47;
          <span key={pathPart} className="text-gray-600">
            {pathPart}
          </span>
        </span>
      );
    } else {
      return <span key={pathPart} className="endpoint-path-url">&#47;{pathPart}</span>;
    }
  });
}

export default Path;
