import React from 'react';
import { isNil, hasPath } from 'ramda';

function Parameters({ methodData }) {
  if (!isNil(methodData.parameters) && methodData.parameters.length > 0) {
    return (
      <div className="overflow-x-auto text-lg font-bold py-4 mdMax:pb-0">
        Parameters
        <table className="table-auto font-normal text-xs">
          <thead>
            <tr>
              <th className="text-left px-2 py-2">name</th>
              <th className="text-left px-2 py-2">value type</th>
              <th className="text-left px-2 py-2">param. type</th>
              <th className="text-left px-2 py-2">description</th>
            </tr>
          </thead>
          <tbody>
            {methodData.parameters.map((param, index) => {
              return (
                <tr
                  key={param.name}
                  className={`${index % 2 === 1 ? 'bg-gray-200' : ''}`}
                >
                  <td className="border px-2 py-2">
                    {param.name}&nbsp;
                      {param.required ? (
                        <span className="text-mg-red italic text-xs"><br />*required</span>
                      ) : null}
                  </td>
                  <td className="border px-2 py-2 text-gray-600">
                    {hasPath(['schema', 'type'], param) ? (
                      param.schema.type
                    ) : (
                      <span className="text-xs bg-mg-white3 rounded p-1">
                        N/A
                      </span>
                    )}
                  </td>
                  <td className="border px-2 py-2">{param.in}</td>
                  <td className="border px-2 py-2">{param.description}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return null;
  }
}

export default Parameters;
