import React from 'react';
import * as MarkdownIt from 'markdown-it';
import { isNil } from 'ramda';

const md = new MarkdownIt();


function MethodDescription({ description }) {
  return (
    <div className="method-description mb-2">
      {!isNil(description) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: md.render(description),
          }}
        />
      ) : null}
    </div>
  );
}

export default MethodDescription;
