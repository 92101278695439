import React from 'react';

import Section from './Section';
import Endpoints from './Endpoints';
import { Link } from './base/Link';

const AppDescription = ({ name }) => {
    switch (name) {
        case 'kwfinder': return (
            <>
                <h2 className="font-bold text-4xl mb-4">KWFinder</h2>
                <p className="text-gray-700 mb-16">
                    You can know this part of API from our keyword tool <Link href='http://mangools.com/kwfinder'>kwfinder.com</Link>. It allows you to get keyword suggestions, keyword metrics and organic SERP results for any seed keyword or keywords related to any URL.
                </p>
            </>
        );
        case 'serpchecker': return (
            <>
                <h2 className="font-bold text-4xl mb-4">SERPChecker</h2>
                <p className="text-gray-700 mb-16">
                    <Link href='https://mangools.com/serpchecker'>SERPChecker</Link>API is great for getting a detailed <Link href='https://mangools.com/serpchecker/features/local-serp-analysis'>localized SERP</Link> details.
                </p>
            </>
        );
        case 'linkminer': return (
            <>
                <h2 className="font-bold text-4xl mb-4">LinkMiner</h2>
                <p className="text-gray-700 mb-16">
                    <Link href='https://mangools.com/linkminer'>LinkMiner</Link> is a part of Mangools API dedicated to backlinks.
                </p>
            </>
        );
        case 'siteprofiler': return (
            <>
                <h2 className="font-bold text-4xl mb-4">SiteProfiler</h2>
                <p className="text-gray-700 mb-16">
                    <Link href='https://mangools.com/siteprofiler'>SiteProfiler</Link> part of Mangools API is dedicated to domain / URL metrics.
                </p>
            </>
        );
        default: return null
    }
}

function Apps({ data }) {
  return (
    <>
      {Object.values(data).map(({ name, paths, description }) => {
        return (
          <Section key={name}>
            <div className="px-16 mt-16 smMax:px-8">
              <AppDescription name={name} />
            </div>
            <Endpoints paths={paths} />
          </Section>
        );
      })}
    </>
  );
}

export default Apps;
