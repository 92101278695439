import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Navigation from '../components/Navigation';

import MobileHeader from './MobileHeader';

const MobileNavigation = ({ data }) => {
  const [isOpen, setOpen] = useState(false);
  const currentURL = typeof window !== 'undefined' && window.location.href;

  useEffect(() => {
    setOpen(false);
  }, [currentURL]);

  const handleMenuToggle = () => {
    setOpen(!isOpen);
  }

  return (
    <>
      <aside className={`nav-panel ${isOpen ? 'nav-panel--active' : ''}`}>
        <Navigation data={data} className='nav--mobile' />
        <button className='btn close-btn' onClick={handleMenuToggle}>
          <FontAwesomeIcon
            icon={faTimes}
            color='#3e4348'
            size='lg'
          />
        </button>
      </aside>
      <MobileHeader onMenuToggle={handleMenuToggle} />
    </>
  );
};

export default MobileNavigation;
