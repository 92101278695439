import * as React from 'react';

type SectionProps = {
  children: React.ReactNode;
};

const Section = ({ children }: SectionProps) => {
  return (
    <section className="section border-gray-300 w-100">
      <div className="flex flex-col">{children}</div>
    </section>
  );
};

export default Section;
