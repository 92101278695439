import * as React from "react"
import Section from "../Section"
import { Link } from '../base/Link';

function Authentication() {
  return (
    <Section>
      <div className="flex flex-wrap">
        <div className="w-1/2 mdMax:w-full" id="authentication">
          <div className="p-16 mdMax:pb-0 smMax:px-8">
            <h2 className="font-semibold text-2xl mb-2">Authentication</h2>
            <p className="text-gray-700">
              Mangools API involves verifying the identity of the client sending a request according to <code className="inline-code">x-access-token</code> (API key) request header.
            </p>
            <div className="mt-8 text-lg font-semibold">How to get API key</div>
            <p className="text-gray-700">
              To get your own API key, please contact us within form on <Link href='https://mangools.com/api'>this page</Link>.
            </p>
          </div>
        </div>
        <div className="w-6/12 p-16 mdMax:w-full mdMax:pt-0 smMax:px-8">
            <div className="mt-8 mb-4 mt-0 text-lg font-semibold">Request example</div>
            <pre>
              <code className="language-js rounded-lg text-sm font-normal">{`fetch('<API_REQUEST_URL>', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'X-access-Token': '<YOUR_API_KEY>' // use this field in headers to authenticate with your API key
    },
  })
  .then(response => {})
  .catch(error => {});`}
          </code>
            </pre>
        </div>
      </div>
    </Section>
  )
}

export default Authentication
