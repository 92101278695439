import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const mangoolsLogo = require('../images/mangools-logo.svg');

const MobileHeader = ({ onMenuToggle }: MobileHeaderProps) => {

  return (
      <div className='mobile-header hidden smMax:flex py-4 px-8'>
        <button onClick={onMenuToggle} className='btn'>
          <FontAwesomeIcon icon={faBars} color='#3e4348' size='2x' />
        </button>
        <a href="/" className="main-logo-link px-5">
          <img className="main-logo" src={mangoolsLogo} alt="Mangools logo" />
          <span className="main-logo-text">/API</span>
        </a>
      </div>
  );
};

interface MobileHeaderProps {
  onMenuToggle(): void;
}

export default MobileHeader;
