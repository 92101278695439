import * as React from "react"

import Section from "../Section"

const errorCodes = [
  ['200 - OK', 'Everything worked as expected.'],
  ['400 - Bad Request', 'The request was unacceptable, often due to missing a required parameter.'],
  ['401 - Unauthorized', 'No valid API key provided.'],
  ['402 - Request Failed', 'The request was unacceptable, often due to missing a required parameter.'],
  ['400 - Bad Request', 'The parameters were valid but the request failed.'],
  ['403 - Forbidden', 'The API key doesn\'t have permissions to perform the request.'],
  ['404 - Not Found', 'The requested resource doesn\'t exist.'],
  ['409 - Conflict', 'The request conflicts with another request (perhaps due to using the same idempotent key).'],
  ['429 - Too Many Requests', 'Too many requests hit the API too quickly. We recommend an exponential backoff of your requests.'],
  ['500, 502, 503, 504 - Server Errors', 'Something went wrong on Mangools\'s end. (These are rare.)']
];

function Errors() {
  return (
    <Section>
      <div className="flex flex-wrap">
        <div className="w-1/2 p-16 mdMax:w-full mdMax:pb-0 smMax:px-8" id="errors">
          <div className="">
            <h2 className="font-semibold text-2xl mb-2">Errors</h2>
            <p className="text-gray-700">
              Mangools API uses conventional HTTP response codes to indicate the success or failure of an API request.
            </p>
            <p className="text-gray-700">
              Codes in the <code className="inline-code">2xx</code> range indicate success.
            </p>
            <p className="text-gray-700">
              Codes in the <code className="inline-code">4xx</code> range indicate an error that failed given the information provided (e.g., a required parameter was omitted, a charge failed, etc.).
            </p>
            <p className="text-gray-700">
              Codes in the <code className="inline-code">5xx</code> range indicate an error with Mangools's servers (these are rare).
            </p>
          </div>
        </div>
        <div className="w-1/2 p-16 mdMax:w-full mdMax:pt-0 smMax:px-8">
          <table className="table-auto font-normal text-xs mt-4">
            <thead>
            <tr>
              <th className="text-left px-2 py-2">Error code</th>
              <th className="text-left px-2 py-2">Description</th>
            </tr>
            </thead>
            <tbody>
              {errorCodes.map(([code, desc], i) => (
                  <tr key={i} className={i % 2 === 1 ? 'bg-gray-200' : ''}>
                    <td className="border px-2 py-2">
                      {code}
                    </td>
                    <td className="border px-2 py-2">
                      {desc}
                    </td>
                  </tr>
              ))}
            </tbody>
          </table>
          <div className="w-6/12"></div>
        </div>
      </div>
    </Section>
  )
}

export default Errors
