import React from 'react';
import { isNil, pipe, mergeAll, map } from 'ramda';

function CodeSample({ methodData, path, methodType }) {
    const methodParams = methodData.parameters || [];
    const params = new URLSearchParams(methodParams.reduce((acc, item) => ({
        ...acc,
        [item.name]: isNil(item?.schema?.example) ? item?.schema?.type : item?.schema?.example
    }), [])).toString();
    const urlParams = params ? `?${params}` : ''

  const getExampleBody = () => {
      if (!isNil(methodData.requestBody)) {

      const { properties } = methodData.requestBody.content[
        'application/json'
      ].schema;
      return pipe(
        map(([field, fieldData]) => {
          if (fieldData.type === 'array') {
            if (!isNil(fieldData.items.example)) {
              return { [field]: fieldData.items.example };
            } else {
              return { [field]: [fieldData.items.type] };
            }
          }

          return { [field]: fieldData.example ?? fieldData.type };
        }),
        mergeAll,
        data => `body: ${JSON.stringify(data, null, 3)}`,
        json => json.replace(/"([^"]+)":/g, '$1:')
      )(Object.entries(properties));
    } else {
      return '';
    }
  };

  return (
    <div>
      <pre>
        <code className="language-js rounded-lg text-sm font-normal">{`fetch('https://api.mangools.com/v3${path+urlParams}', {
  method: '${methodType.toUpperCase()}',
  mode: 'cors',
  headers: {
    'X-access-Token': '<YOUR_API_KEY>'
  },
  ${getExampleBody()}
})
.then(response => {})
.catch(error => {});`}
        </code>
      </pre>
    </div>
  );
}

export default CodeSample;
