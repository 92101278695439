import * as React from 'react';

type NavigationItemProps = {
  children: React.ReactNode;
  href: string;
};

const NavigationItem = ({ children, href }: NavigationItemProps) => {
  return <a href={`#${href}`} className="text-sm text-gray-600">{children}</a>;
};

export default NavigationItem;
