import React from 'react';

import Parameters from './Parameters';
import MethodDescription from './MethodDescription';
import Path from './Path';
import CodeSample from './CodeSample';
import ResponseExample from './ResponseExample';
import RequestMethodBadge from './RequestMethodBadge';
import ResponseAttributes from './ResponseAttributes';

function Endpoints({ paths }) {
  return paths.map(({ path, data }) => {
    data = JSON.parse(data);
    return Object.entries(data).map(([methodType, methodData]) => {
      return (
        <div
          key={`${methodType}-${path}`}
          id={`${methodType}-${path}`}
          className="flex border-t border-gray-400 overflow-hidden pt-16 md:px-16 flex-wrap smMax:px-8"
        >
          <div className="w-1/2 mb-8 mdMax:w-full pr-16 mdMax:pr-0 ">
            <div className="mb-5">
              <div className="text-2xl mb-4 font-bold">
                {methodData.summary}
              </div>
              <div className="endpoint-path text-sm">
                <RequestMethodBadge methodType={methodType} />
                <Path path={path} />
              </div>
              <div className="py-4 text-gray-700">
                <MethodDescription description={methodData.description} />
                <Parameters methodData={methodData} />
                <ResponseAttributes methodData={methodData} />
              </div>
            </div>
          </div>
          <div className="w-1/2 mb-16 mdMax:w-full endpoint-right">
            <div className="mb-2 text-white endpoint-right-header font-bold">Code example (JavaScript)</div>
            <CodeSample
              methodData={methodData}
              path={path}
              methodType={methodType}
            />
            <ResponseExample methodData={methodData} />
          </div>
        </div>
      );
    });
  });
}

export default Endpoints;
