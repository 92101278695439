import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import GTMService from 'mangools-commons/lib/services/GTMService';
import UsercomService from 'mangools-commons/lib/services/UsercomService';

import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import Content from '../components/Content';
import MobileNavigation from '../components/MobileNavigation';

const IndexPage = () => {
  useEffect(() => {
    GTMService.initialize({
      gtmId: process.env.GATSBY_GTM_ID,
      auth: process.env.GATSBY_GTM_AUTH,
      preview: process.env.GATSBY_GTM_PREVIEW,
    });

    UsercomService.initChatWidet({
      userId: null,
      apiKey: process.env.GATSBY_USERCOM_CHAT_WIDGET_API_KEY,
      subdomain: process.env.GATSBY_USER_COM_SUBDOMAIN,
    });
  }, []);

  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allMangoolsApp {
        nodes {
          name
          description
          paths {
            path
            data
          }
        }
      }
    }
  `);

  const nodes = data.allMangoolsApp.nodes;

  return (
    <Layout>
      <MobileNavigation data={nodes} />
      <Navigation data={nodes} />
      <Content data={nodes} />
    </Layout>
  );
};

export default IndexPage;
