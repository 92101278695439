import React from 'react';
import { hasPath, isNil, reduce, pipe, values } from 'ramda';

function ResponseSample({ methodData }) {

  const renderResponseBody = () => {
    if (!isNil(methodData.responses)) {
      const response = Object.values(methodData.responses)[0];

      if (hasPath(['content', 'application/json'], response)) {
        const { schema } = response.content['application/json'];

        if (schema.properties || schema.items) {
          const schemaProperties = schema.properties ?? schema.items.properties;
          function resolveField(data) {
            return reduce(
              (res, [field, fieldData]) => {
                if (!fieldData) {
                  return res;
                }
                if (!isNil(fieldData?.properties)) {
                  return {
                    ...res,
                    [field]: { ...resolveField(fieldData.properties) },
                  };
                }

                if (fieldData.type === 'array' && fieldData.items && fieldData.items[0]) {
                  const firstField = fieldData.items[0];
                  return {
                    ...res,
                    [field]: [
                      resolveField(firstField?.properties || firstField)
                    ],
                  };
                }

                if (fieldData.items && fieldData.items.type === 'object' && fieldData.type !== 'array') {
                  console.log("type === 'object'", fieldData)
                  return {
                      ...res,
                          [field]: [
                            resolveField(fieldData.items.properties)
                          ],
                  };
                }

                return {
                  ...res,
                  [field]: fieldData.example,
                };
              },
              {},
              Object.entries(data)
            );
          }

          const resolvedData = pipe(
            data => resolveField(data),
            data => {
              if (schema.type === 'array') {
                return [data];
              } else {
                return data;
              }
            },
            data => JSON.stringify(data, null, 2)
          )(schemaProperties);

          return resolvedData;
        }
      }
      return null;
    } else {
      return null;
    }
  };

  if (!isNil(values(methodData.responses)[0].content)) {
    return (
      <div>
        <div className="font-bold mb-2 text-white endpoint-right-header">Response body</div>
        <pre>
          <code className="language-json rounded-lg text-sm font-normal">
            {renderResponseBody()}
          </code>
        </pre>
      </div>
    );
  } else {
    return null;
  }
}

export default ResponseSample;
