import * as React from 'react';
import { Helmet } from 'react-helmet';

import Introduction from './sections/Introduction';
import Authentication from './sections/Authentication';
import Errors from './sections/Errors';
import Apps from './Apps';
import SEO from "./seo"

function Content({ data }) {

  return (
    <>
      <SEO />
      <div className="content">
        <Introduction />
        <Authentication />
        <Errors />
        <Apps data={data} />
      </div>
    </>
  );
}

export default Content;
