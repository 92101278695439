import React from 'react';

function RequestMethodBadge({ methodType }) {
  return (
    <span
      className={`badge nline-block text-xs border-opacity-75 bg-mg-light-blue text-gray-800 font-bold p-1 mr-2 rounded`}
    >
      {methodType.toUpperCase()}
    </span>
  );
}

export default RequestMethodBadge;
