import React from 'react';
import { hasPath } from 'ramda';

const reduceAttributes = (res) => (acc, attrKey) => {
  const responseAttr = res[attrKey];

  if (responseAttr?.type === 'array' && responseAttr?.items?.length > 0) {
    const item = responseAttr?.items[0];
    return ({
      ...acc,
      ...Object.keys(item).reduce(reduceAttributes(item), {}),
    })
  }

  if (responseAttr?.type === 'object' && responseAttr?.properties) {
    const responseAttrProps = responseAttr.properties;
    return ({
      ...acc,
      ...Object.keys(responseAttrProps).reduce(reduceAttributes(responseAttrProps), {}),
    });
  }

  const desc = responseAttr?.description;
  if (desc && desc !== attrKey && desc !== '') {
    return ({
      ...acc,
      [attrKey]: responseAttr,
    })
  }

  return acc;
}

const ResponseAttributes = ({ methodData }) => {
  if (!hasPath(['200', 'content', 'application/json', 'schema', 'properties'], methodData.responses)) {
    return null;
  }

  const response = methodData.responses[200].content['application/json'].schema.properties;
  const mergedAttrs = Object.keys(response).reduce(reduceAttributes(response), {});

  if (Object.keys(mergedAttrs).length === 0) {
    return null;
  }

  return (
    <div className="overflow-x-auto text-lg font-bold py-4 mdMax:pb-0">
      Response attributes
      <table className="table-auto font-normal text-xs">
        <thead>
          <tr>
            <th className="text-left px-2 py-2">name</th>
            <th className="text-left px-2 py-2">description</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(mergedAttrs).map((key, index) => {
            const param = mergedAttrs[key];

            return (
              <tr
                key={key}
                className={`${index % 2 === 1 ? 'bg-gray-200' : ''}`}
              >
                <td className="border px-2 py-2">{key}</td>
                <td className="border px-2 py-2">{param?.description}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ResponseAttributes;
