import { isNil } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import classnames from 'classnames';

import NavigationItem from './NavigationItem';
const mangoolsLogo = require('../images/mangools-logo.svg');

const NavigationPathItem = ({ methodId, summary }) => {
  useEffect(() => {
    if (window) {
      const { hash } = window.location;

      const el = document.querySelector(
        `[data-methodid="${hash.replace(/\//g, '\\/')}"]`
      );

      if (!isNil(el)) {
        document.querySelector('nav').scrollTop =
          el.offsetTop - el.scrollTop + el.clientTop;
      }
    }
  }, []);
  return (
    <div className="normal-case font-normal mb-2" data-methodid={methodId}>
      <a href={methodId}>{summary}</a>
    </div>
  );
};

const Navigation = ({ data, className }) => {
  return (
    <div className={classnames("nav hidden w-64 flex-col md:flex border-gray-300 pb-5", className)}>
      <div className="sticky top-5">
        <a href="/" className="main-logo-link mb-4 px-5">
          <img className="main-logo" src={mangoolsLogo} alt="Mangools logo" />
          <span className="main-logo-text">/API</span>
        </a>
        <nav className="overflow-y-auto">
          <div className="px-5 d-flex flex-column">
            <div className='pb-1'>
              <NavigationItem href="authentication">Authentication</NavigationItem>
            </div>
            <div>
              <NavigationItem href="errors">Errors</NavigationItem>
            </div>
          </div>
          {data.map(({ name, paths, ...rest }) => {
            return (
              <div
                key={name}
                className="text-gray-600 uppercase text-sm font-medium mb-10 px-5 mt-10"
              >
                <div className="mb-2 font-bold">{name}</div>
                <div>
                  {paths.map(({ path, data }) => {
                    const pathData = JSON.parse(data);

                    return Object.entries(pathData).map(
                      ([methodType, method]) => {
                        return (
                          <NavigationPathItem
                            key={`${methodType}-${path}`}
                            methodId={`#${methodType}-${path}`}
                            methodType={methodType}
                            summary={method.summary}
                            path={path}
                          />
                        );
                      }
                    );
                  })}
                </div>
              </div>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
